import lodashIsNumber from 'lodash-es/isNumber';
import type { ComputedRef, Ref } from 'vue';

import { type AuctionItemEntity, type AuctionListingItemEntity, AuctionState, BackendErrorCode } from '~/apiClient';

type UseAuctionStatusAdapterReturn = {
    statusSectionColor: ComputedRef<string>;
    title: ComputedRef<string | undefined>;
    titleColor: ComputedRef<string | undefined>;
    titleAnnotation: ComputedRef<string | undefined>;
    titleAnnotationColor: ComputedRef<string | undefined>;
    priceColor: ComputedRef<string | undefined>;
    isFeeNoticeHidden: ComputedRef<boolean>;
    isCountdownVisible: ComputedRef<boolean>;
    hasRestrictedNotice: ComputedRef<boolean>;
    isBailNeeded: ComputedRef<boolean>;
    hasReserveNotice: ComputedRef<boolean>;
    hasInspectionNotice: ComputedRef<boolean>;
    activeAutoBidAmount: ComputedRef<number | undefined>;
};

export default function useAuctionStatus(auction: Ref<AuctionItemEntity | AuctionListingItemEntity>): UseAuctionStatusAdapterReturn {
    const { tt } = useTypedI18n();
    const userMeta = computed(() => auction.value.userMeta ?? null);

    const negotiationInProgress = computed(
        () => auction.value.state === AuctionState.Expired && userMeta.value?.maxBidder && !userMeta.value?.reservePriceReached
    );
    const isPriceHidden = computed(() => !lodashIsNumber(auction.value.currentPrice));
    const isFeeNoticeHidden = computed(
        () =>
            isPriceHidden.value || !(<AuctionState[]>[AuctionState.Waiting, AuctionState.Pending, AuctionState.Running]).includes(auction.value.state)
    );
    const isCountdownVisible = computed(() => auction.value.state === AuctionState.Pending || auction.value.state === AuctionState.Running);

    const statusSectionColor = computed(() => {
        if (auction.value.state === AuctionState.Running) {
            if (userMeta.value?.maxBidder) return 'bg-green-50';
            if (userMeta.value?.outBid) return 'bg-amber-50';

            return 'bg-primary-50';
        }

        if (auction.value.state === AuctionState.Expired && userMeta.value?.maxBidder) return 'bg-green-50';

        return 'bg-neutral-100';
    });

    const title = computed(() => {
        switch (auction.value.state) {
            case AuctionState.Draft:
            case AuctionState.ToBeApproved:
            case AuctionState.ToBeScheduled:
                return tt('auction.auctionPanel.reservePrice');
            case AuctionState.Pending:
                return tt('auction.auctionPanel.startingPrice');
            case AuctionState.Running:
                return tt('auction.auctionPanel.current');
            case AuctionState.Canceled:
                return tt(`auction.auctionPanel.state.${AuctionState.Canceled}`);
            case AuctionState.Expired:
                return userMeta.value?.maxBidder && userMeta.value?.reservePriceReached
                    ? tt('auction.auctionPanel.wonAuction')
                    : tt(`auction.auctionPanel.state.${AuctionState.Expired}`);
        }
    });
    const titleColor = computed(() => {
        if (auction.value.state === AuctionState.Expired && userMeta.value?.maxBidder) return 'text-green-600';
    });

    const titleAnnotation = computed(() => {
        if (auction.value.state === AuctionState.Running) {
            if (userMeta.value?.maxBidder) return tt('auction.auctionPanel.maxBidder');
            if (userMeta.value?.outBid) return tt('auction.auctionPanel.outBid');
        }

        if (negotiationInProgress.value) return tt('auction.auctionPanel.negotiationInProgress');
    });
    const titleAnnotationColor = computed(() => {
        if (auction.value.state === AuctionState.Running) {
            if (userMeta.value?.maxBidder) return 'text-green-600';
            if (userMeta.value?.outBid) return 'text-yellow-600';
        }

        if (negotiationInProgress.value) return 'text-green-600';
    });

    const priceColor = computed(() => {
        if (isPriceHidden.value) return 'text-black';
        if (auction.value.state === AuctionState.Expired) return userMeta.value?.maxBidder ? 'text-green-800' : 'text-black';

        return 'text-primary';
    });

    const isBailNeeded = computed(() => !userMeta.value?.canBid && userMeta.value?.cantBidReason === BackendErrorCode.CantBidUserNoBail);
    const hasRestrictedNotice = computed(
        () =>
            !!userMeta.value &&
            !userMeta.value?.canBid &&
            userMeta.value?.cantBidReason !== BackendErrorCode.CantBidAuctionIsNotRunning &&
            !isBailNeeded.value
    );

    const hasReserveNotice = computed(() => userMeta.value?.reservePriceReached === false);
    const hasInspectionNotice = computed(() => !!userMeta.value?.inspectionExpiredExportRestrictions);

    const activeAutoBidAmount = computed<number | undefined>(() => (userMeta.value?.autoBid?.active ? userMeta.value?.autoBid?.amount : undefined));

    return {
        statusSectionColor,
        title,
        titleColor,
        titleAnnotation,
        titleAnnotationColor,
        priceColor,
        isFeeNoticeHidden,
        isCountdownVisible,
        hasRestrictedNotice,
        isBailNeeded,
        hasReserveNotice,
        hasInspectionNotice,
        activeAutoBidAmount,
    };
}

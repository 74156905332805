<script setup lang="ts">
import type { PropType } from 'vue';

import { type AuctionItemEntity, type AuctionListingItemEntity, AuctionState } from '~/apiClient';

const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionListingItemEntity | AuctionItemEntity>, required: true },
});

const { tt } = useTypedI18n();

const isPending = computed(() => auction.state === AuctionState.Pending);

const { totalSeconds, countdownLabel, timeDatetimeAttribute } = useCountdown(toRef(() => (isPending.value ? auction.startAt : auction.expiresAt)));

const textColor = computed(() => (auction.state === AuctionState.Running && totalSeconds.value < 60 * 15 ? 'text-yellow-500' : 'text-primary'));
</script>

<template>
    <span v-if="totalSeconds > 0">
        <span>{{ tt(isPending ? 'auction.countdown.startsIn' : 'auction.countdown.endsIn') }}:&nbsp;</span>
        <time :class="textColor" :datetime="timeDatetimeAttribute">{{ countdownLabel }}</time>
    </span>
</template>

import { objectEntries } from '@vueuse/core';
import lodashPickBy from 'lodash-es/pickBy';

type UseCountdownReturn = {
    totalSeconds: Ref<number>;
    countdownLabel: Ref<string>;
    timeDatetimeAttribute: Ref<string>;
};

const TIME_UNITS = ['days', 'hours', 'minutes', 'seconds'] as const;
type TimeUnitsKey = (typeof TIME_UNITS)[number];

const SECONDS_MINUTE = 60;
const SECONDS_HOUR = 60 * SECONDS_MINUTE;
const SECONDS_DAY = 24 * SECONDS_HOUR;

export const useCountdown = (expirationTime: Ref<string | null | undefined>): UseCountdownReturn => {
    const { timeSyncNow } = useTimeSync();
    const { tt } = useTypedI18n();

    const unitLabels = computed(
        () => Object.fromEntries(TIME_UNITS.map(unit => [unit, tt(`auction.countdown.${unit}`)])) as Record<TimeUnitsKey, string>
    );

    const totalSeconds = computed(() =>
        expirationTime.value ? Math.max(Math.round((Date.parse(expirationTime.value) - timeSyncNow.value) / 1000), 0) : 0
    );

    const usefulTimeUnits = computed(() => {
        const totS = totalSeconds.value;

        const units = {
            days: totS >= SECONDS_DAY && Math.floor(totS / SECONDS_DAY),
            hours: totS >= SECONDS_HOUR && Math.floor((totS % SECONDS_DAY) / SECONDS_HOUR),
            minutes: totS >= SECONDS_MINUTE && Math.floor((totS % SECONDS_HOUR) / SECONDS_MINUTE),
            seconds: totS < SECONDS_HOUR && totS % SECONDS_MINUTE,
        };

        return lodashPickBy(units, val => val !== false) as Partial<Record<TimeUnitsKey, number>>;
    });

    const countdownLabel = computed(() =>
        objectEntries(usefulTimeUnits.value)
            .map(([unit, value]) => `${value}${unitLabels.value[unit]}`)
            .join(' ')
    );

    const timeDatetimeAttribute = computed(() => {
        const unitsMap: Record<TimeUnitsKey, string> = {
            days: 'D',
            hours: 'H',
            minutes: 'M',
            seconds: 'S',
        };

        const daysPart = usefulTimeUnits.value.days ? `${usefulTimeUnits.value.days}${unitsMap.days}` : '';
        const timePart = objectEntries(usefulTimeUnits.value)
            .filter(([unit]) => unit !== 'days')
            .map(([unit, value]) => `${value}${unitsMap[unit]}`)
            .join('');

        return `P${daysPart}T${timePart}`;
    });

    return {
        totalSeconds,
        countdownLabel,
        timeDatetimeAttribute,
    };
};
